import dayjs from 'dayjs';
import {
  ActivityLogStatus,
  AdditionalNoticeStatus,
  AdditionalNoticesType,
  DurationType,
  EProceedingStatus,
  EProceedingType,
  ItrFillingType,
  NoticeAndOrderStatus,
  NoticeAndOrderType,
  SyncLogStatus,
} from '../__generated__/graphql';

export const DETAIL = {
  CONTACT_MAIL: 'contact@counselvise.com',
  CONTACT_NUMBER: '+91 97234 00220',
};
/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  LOGIN: '/login',
  SIGNUP: '/signup',
  AUTHENTICATION: '/authentication',
  DIRECT_TAX: 'direct-tax',
  INDIRECT_TAX: 'indirect-tax',
  CLIENT: 'assessee-details',
  PAN_DASHBOARD: ':id/pan-dashboard',
  COMPANY_DASHBOARD: ':id/company-dashboard',
  SYNC_LOG: 'sync-log',
  FILED_RETURN: 'filed-return-forms',
  FILED_RETURN_STATUS: ':resId/status',
  OTHER_FILED: 'other-filed-form',
  OTHER_FILED_DETAILS: ':resId/details',
  TAX_LITIGATION: 'e-proceeding-data',
  NOTICES: 'notices',
  NOTICE_ORDERS: 'notices-&-orders',
  ADDITIONAL_NOTICE_ORDERS: 'additional-notices-&-orders',
  E_PROCESSING_RESPONSE: ':resId/response',
  TRACK_RETURN_STATUS: 'track-return-status',
  REFUND: '/refund',
  APPEALS: '/appeals',
  RESPONSE: '/response',
  DETAIL: 'details',
  COMPANY: 'company-list',
  NOTICE_BOARD: 'notice-board',
  PRIVACY_POLICY: 'privacy-policy',
  TERMS_AND_CONDITIONS: 'terms-and-conditions',
  JUDGEMENTS: '/judgements',
  CONSULTATION: '/consultation',
  TEMPLATES: '/templates',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
  CLIENT: 'All Clients',
  FILED_RETURN: 'Filed Return Form',
  OTHER_FILED: 'Other Filed Form',
  TAX_LITIGATION: 'Tax Litigation',
  NOTICE_ORDERS: 'Notices',
  TRACK_RETURN_STATUS: 'Track Return Status',
  REFUND: 'Refund',
  APPEALS: 'Appeals',
};

export const INDIRECT_TAX = 'indirect-tax';

export const LIMIT = 10;
export const MIN_LIMIT = 3;
export const PLAN_LIMIT = 20;
export const MAX_LIST_NOTICE = 5;
export const CURRENT_PAGE = 1;

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const NA_PLACEHOLDER_TEXT = '-';
export const ZERO_PLACEHOLDER_TEXT = 0;

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
  NOT_ACCEPTE_PHONE: /^(?!\d{10}$).+/,
  PAN_NUMBER: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
};
export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};
export const ACTIVATION = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const ACTIVE_STATUS = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];
export const STATUS = [
  { label: 'Success', value: ActivityLogStatus?.Success },
  { label: 'Failed', value: ActivityLogStatus?.Failed },
  { label: 'Pending', value: ActivityLogStatus?.Pending },
];
export const SYNC_STATUS = [
  { label: 'All', value: SyncLogStatus?.All },
  { label: 'Success', value: SyncLogStatus?.Success },
  { label: 'Failed', value: SyncLogStatus?.Failed },
];
export const FILED_STATUS = [
  { label: 'All', value: '' },
  { label: 'Verified', value: 'Verified' },
  { label: 'For verification', value: 'For verification' },
];
export const FILING_TYPE = [
  { label: 'All', value: ItrFillingType.All },
  { label: 'Original', value: ItrFillingType.Original },
  { label: 'Other', value: ItrFillingType.Others },
];

export const SyncLogActivity = {
  Login: 'LOGIN',
  ManualSync: 'MANUAL_SYNC',
  Sync: 'SYNC',
};

export const E_PROCEEDINGS_STATUS = [
  {
    label: 'Closed',
    value: EProceedingStatus.Closed,
  },
  {
    label: 'Pending',
    value: EProceedingStatus.Pending,
  },
  {
    label: 'Replied',
    value: EProceedingStatus.Replied,
  },
];

export const DURATION_TYPE = [
  {
    label: 'All',
    value: DurationType.All,
  },
  {
    label: 'Due Today',
    value: DurationType.DueToday,
  },
  {
    label: 'Over Dues',
    value: DurationType.OverDues,
  },
  {
    label: 'Last 24 Hours',
    value: DurationType.Last_24Hours,
  },
  {
    label: 'Due In 7 Days',
    value: DurationType.DueIn_7Days,
  },
  {
    label: 'Custom',
    value: DurationType.Custom,
  },
];

export const DURATION_REDIRECT_TYPE = [
  {
    label: 'allNotices',
    value: DurationType.All,
  },
  {
    label: 'dueToday',
    value: DurationType.DueToday,
  },
  {
    label: 'overDue',
    value: DurationType.OverDues,
  },
  {
    label: 'last24Hours',
    value: DurationType.Last_24Hours,
  },
  {
    label: '7DaysDue',
    value: DurationType.DueIn_7Days,
  },
  {
    label: 'Custom',
    value: DurationType.Custom,
  },
];

export const E_PROCEEDINGS_TYPE = [
  {
    label: 'For Your Information',
    value: EProceedingType.Fyi,
  },
  {
    label: 'For Your Action',
    value: EProceedingType.Fya,
  },
];

export const PROCEEDING_NAME_LIST = [
  { label: 'First Appeal Proceedings', value: 'First Appeal Proceedings' },
  {
    label: 'Rectification Proceeding u/s 154 r.w.s 250',
    value: 'Rectification Proceeding u/s 154 r.w.s 250',
  },
  { label: 'Issue Letter', value: 'Issue Letter' },
  {
    label: 'Penalty Proceeding u/s 154 r.w.s 271(1)(c)',
    value: 'Penalty Proceeding u/s 154 r.w.s 271(1)(c)',
  },
  {
    label: 'Rectification Proceeding u/s 154 r.w.s 254',
    value: 'Rectification Proceeding u/s 154 r.w.s 254',
  },
  {
    label: 'Assessment Proceeding u/s 143(3)',
    value: 'Assessment Proceeding u/s 143(3)',
  },
  { label: 'Grievance Application', value: 'Grievance Application' },
  { label: 'Penalty Proceeding', value: 'Penalty Proceeding' },
  {
    label: 'Give effect Proceeding u/s 250 r.w.s 143(3)',
    value: 'Give effect Proceeding u/s 250 r.w.s 143(3)',
  },
  {
    label: 'Notice u/s 142(1)',
    value: 'Notice u/s 142(1)',
  },
  {
    label: 'Others',
    value: '',
  },
];

export const MESSAGE = {
  required: 'This field is required',
};

export const PROCEEDING_NAME = {
  Others: 'Others',
};

export const color = '#04724D';

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const COMPANY_INFO = { COOKIE_EXPIRY: 259200 };

export const dateFormat = 'YYYY/MM/DD';
export const defaultDateFormat = 'DD-MM-YYYY';
export const defaultTimeFormat = 'h:mmA';
export const defaultDateTimeFormat = 'DD-MM-YYYY HH:mm:ss';
export const currentDate = dayjs().startOf('day');
export const upComingDays = dayjs().add(2, 'day').endOf('day');

export const AI_TOOLTIP_MESSAGE = 'Get your response with CounselVise AI';

export const ITR_FILL_START_YEAR = 1991;

export const EMPTY_STATE = { emptyText: 'There are no record to display' };
export const EMPTY_DUE_DATES_TEXT = { emptyText: 'No nearing due dates' };

export const FEATURE_NOTICE_BOARD_DIRECT_TAX = 'NOTICE_BOARD_DIRECT_TAX';
export const FEATURE_NOTICE_BOARD_INDIRECT_TAX = 'NOTICE_BOARD_INDIRECT_TAX';

export const COMMON_QUERY_PARAMS = {
  STATUS: 'status',
  DURATION: 'duration',
  START: 'start',
  END: 'end',
  E_PROCEEDING_TYPE: 'epro-type',
  E_PROCEEDING_NAME: 'epro-name',
  CLIENT: 'client',
  NAME: 'name',
  SKIP: 'skip',
  PAGE: 'page',
  LIMIT: 'limit',
  SEARCH: 'search',
  CLIENT_LABEL: 'client-lab',
  ASSESSEE_ID: 'asses-id',
  COMPANY_ID: 'comp-id',
  ASSESSMENT_YEAR: 'asses-year',
  NOTICE_TYPE: 'notc-typ',
  SECTION: 'sectn',
  NOTICE_ID: 'not-id',
  SORT_ASSESSMENT_YEAR: 's-ass-yr',
  SORT_FINANCIAL_YEAR: 's-fin-yr',
  SORT_ISSUED_DATE: 's-iss-dt',
  SORT_RESPONSE_DUE_DATE: 's-res-du-dt',
  SORT_STATUS: 's-sts',
  SORT_ISSUANCE_DATE: 's-issnc-dt',
  SORT_BUSINESS_NAME: 's-business-nm',
  SORT_DUE_DATE: 's-due-dt',
  SORT_REPLY_DUE_DATE: 's-rep-due-dt',
  IS_ISSUED_ON: 'is_issued_on',
  IS_ACK: 'isAck',
};

export const NOTICE_ORDER_TYPE_OPTIONS = [
  {
    label: 'Notice',
    value: NoticeAndOrderType.Notice,
  },
  {
    label: 'Order',
    value: NoticeAndOrderType.Order,
  },
];

export const NOTICE_ORDER_STATUS_OPTIONS = [
  {
    label: 'Open',
    value: NoticeAndOrderStatus.Open,
  },
  {
    label: 'Closed',
    value: NoticeAndOrderStatus.Closed,
  },
];

export const ADDITIONAL_NOTICE_TYPE = [
  {
    label: 'Notice',
    value: AdditionalNoticesType.Notice,
  },
  {
    label: 'Order',
    value: AdditionalNoticesType.Order,
  },
  {
    label: 'Intimation',
    value: AdditionalNoticesType.Intimation,
  },
];

export const ADDITIONAL_NOTICE_STATUS_OPTIONS = [
  {
    label: 'Open',
    value: AdditionalNoticeStatus.Open,
  },
  {
    label: 'Closed',
    value: AdditionalNoticeStatus.Closed,
  },
];

export const NOTICE_TABS = {
  noticeOrder: 'NOTICE_&_ORDER',
  additionalNotices: 'ADDITIONAL_NOTICE',
};

export const SELECT_CLIENT_MESSAGE = 'Please Select Client';

export const CALENDAR_PAYLOAD_TYPE = {
  DIRECT_TAX: 'DIRECT_TAX',
  INDIRECT_TAX: 'INDIRECT_TAX',
};
export const IMPORT_FAILED_MESSAGE = 'Companies import failed';
export const ASSESSEES_IMPORT_FAILED_MESSAGE = 'Assessees import failed';
export const GST_START_YEAR = 2017;

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnType, TableProps } from 'antd/es/table';
import dayjs from 'dayjs';
import { isEmpty, omit } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import {
  Attachment,
  DurationType,
  EProceeding,
  EProceedingSortOn,
  EProceedingsSort,
  EProceedingType,
  SortOrder,
} from '../../../__generated__/graphql';
import {
  Export,
  EyeIcon,
  FilePDF,
  Icon,
  Minus,
  Pencil,
  SelectDropdownIcon,
} from '../../../assets/svg';
import axiosInstance from '../../../common/axiosInstance';
import {
  AI_TOOLTIP_MESSAGE,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  DURATION_TYPE,
  E_PROCEEDINGS_STATUS,
  E_PROCEEDINGS_TYPE,
  EMPTY_STATE,
  ITR_FILL_START_YEAR,
  LIMIT,
  MESSAGE,
  PROCEEDING_NAME,
  PROCEEDING_NAME_LIST,
  ROUTES,
} from '../../../common/constants';
import useQueryValue from '../../../common/hooks/useQueryValue';
import { LABEL_TEXT } from '../../../common/labelTexts';
import {
  downloadCsv,
  formValidatorRules,
  generateYearOptions,
} from '../../../common/utils';
import CommonPagination from '../../../components/CommonPagination';
import CommonSearch from '../../../components/CommonSearch';
import TableComponent from '../../../components/CommonTable';
import StatusTag from '../../../components/CommonTag';
import InfiniteSelect from '../../../components/InfiniteSelect';
import CommonModal from '../../../components/modals/CommonModal';
import MyBreadcrumb from '../../../components/MyBreadcrumb';
import useRouter from '../../../hooks/useRouter';
import { FormValues, SelectedRowKeysState } from '../../../types/common.type';
import { ASSESSEE } from '../clients/graphql/Queries';
import { ASSESSEES_LIST } from '../filed-return/graphql/Queries';
import { UPDATE_E_PROCEEDING_STATUS } from './graphql/Mutation';
import { E_PROCEEDINGS_LIST } from './graphql/Queries';
const { Text } = Typography;
const { required } = formValidatorRules;
const { RangePicker } = DatePicker;

const fieldToParams: Record<string, string> = {
  assessmentYear: COMMON_QUERY_PARAMS.SORT_ASSESSMENT_YEAR,
  financialYear: COMMON_QUERY_PARAMS.SORT_FINANCIAL_YEAR,
  issuedOn: COMMON_QUERY_PARAMS.SORT_ISSUED_DATE,
  responseDueDate: COMMON_QUERY_PARAMS.SORT_RESPONSE_DUE_DATE,
  status: COMMON_QUERY_PARAMS.SORT_STATUS,
};
const TaxLitigation = () => {
  const [statusForm] = Form?.useForm();
  const [filterForm] = Form?.useForm();
  const {
    navigate,
    params: { id },
  } = useRouter();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [attachments, setAttachment] = useState<Attachment[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<SelectedRowKeysState>(
    {},
  );
  const [isTableSorterChange, setIsTableSorterChange] =
    useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const urlDataObj = useQueryValue();
  const currentPage = urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
    ? Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE])
    : 1;
  const [searchParams] = useSearchParams();
  const client = searchParams.get(COMMON_QUERY_PARAMS.CLIENT);
  const clientLabel = searchParams.get(COMMON_QUERY_PARAMS.CLIENT_LABEL);
  const name = searchParams.get(COMMON_QUERY_PARAMS.NAME);

  const askAI = process.env.REACT_APP_ASK_AI_REDIRECT_URL!;

  const initialValue = {
    filters: {
      assesseeId:
        (client || id) && (clientLabel || name)
          ? {
              value: client || id || null,
              label:
                clientLabel && !name
                  ? clientLabel
                  : [clientLabel, name].filter(Boolean).join(' | ') || null,
            }
          : null,
      name: urlDataObj?.[COMMON_QUERY_PARAMS.E_PROCEEDING_NAME],
      assessmentYear: urlDataObj?.[COMMON_QUERY_PARAMS.ASSESSMENT_YEAR] || null,
      type: urlDataObj?.[COMMON_QUERY_PARAMS.E_PROCEEDING_TYPE] || null,
      ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
        DurationType.Custom && {
        period: [
          urlDataObj?.[COMMON_QUERY_PARAMS.START]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.START],
                defaultDateFormat,
              ).startOf('day')
            : '',
          urlDataObj?.[COMMON_QUERY_PARAMS.END]
            ? dayjs(
                urlDataObj?.[COMMON_QUERY_PARAMS.END],
                defaultDateFormat,
              ).endOf('day')
            : '',
        ],
      }),
      status: urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] || null,
      skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
        ? (currentPage - 1) * LIMIT
        : 0,
      search,
      limit: urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT] || LIMIT,
      duration: urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] || null,
    },
  };

  const filters = initialValue.filters;
  const [getAssesseeDetailsCall] = useLazyQuery(ASSESSEE, {
    onError() {},
  });

  useEffect(() => {
    if (id) {
      getAssesseeDetailsCall({
        variables: { where: { id: id } },
        onCompleted: ({ assessee }) => {
          filterForm.setFieldsValue({
            filters: {
              ...filters,
              assesseeId: {
                value: assessee?._id ?? '',
                label: `${assessee?.username ?? ''}|${assessee?.name ?? ''}`,
              },
            },
          });
        },
      });
    } else {
      filterForm.setFieldsValue({ filters: filters });
    }
  }, [location.search]);

  const totalSelectedRows = Object?.values(selectedRowKeys)?.flat();
  const updateBulkStatus = Form.useWatch('statusForm', statusForm);

  useEffect(() => {
    setSelectedRowKeys(() => ({}));
  }, []);

  const resetPagination = () => {
    searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
    searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
  };

  /* any used due to type incompatibility */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateRangeNavigation = (date: any) => {
    if (date && date.length > 0) {
      const startDate = dayjs(date[0]).format(defaultDateFormat);
      const endDate = dayjs(date[1]).format(defaultDateFormat);
      searchParams.set(COMMON_QUERY_PARAMS.START, startDate);
      searchParams.set(COMMON_QUERY_PARAMS.END, endDate);
      searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
    } else {
      if (
        searchParams.has(COMMON_QUERY_PARAMS.START) ||
        searchParams.has(COMMON_QUERY_PARAMS.END) ||
        searchParams.has(COMMON_QUERY_PARAMS.IS_ISSUED_ON)
      ) {
        searchParams.delete(COMMON_QUERY_PARAMS.START);
        searchParams.delete(COMMON_QUERY_PARAMS.END);
        searchParams.delete(COMMON_QUERY_PARAMS.IS_ISSUED_ON);
      }
    }
    resetPagination();
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const prepareQueryVariableFromUrl = useMemo(() => {
    let prepareSorterFromURL: {
      sortBy: SortOrder;
      sortOn: EProceedingSortOn;
    }[] = [];
    Object.keys(fieldToParams).forEach((item) => {
      if (searchParams.has(fieldToParams?.[item as string])) {
        const value = searchParams.get(fieldToParams?.[item as string]);
        prepareSorterFromURL.push({
          sortBy: value as SortOrder,
          sortOn: item as EProceedingSortOn,
        });
      }
    });
    if (prepareSorterFromURL.length === 0 && !isTableSorterChange) {
      prepareSorterFromURL = [
        { sortBy: SortOrder.Desc, sortOn: EProceedingSortOn.ResponseDueDate },
      ];
    }

    if (isTableSorterChange && prepareSorterFromURL.length === 0) {
      prepareSorterFromURL = [
        { sortBy: SortOrder.Desc, sortOn: EProceedingSortOn.CreatedAt },
      ];
    }

    return {
      filter: {
        assesseeId: urlDataObj?.[COMMON_QUERY_PARAMS.ASSESSEE_ID] || id || null,
        name: urlDataObj?.[COMMON_QUERY_PARAMS.E_PROCEEDING_NAME],
        assessmentYear:
          urlDataObj?.[COMMON_QUERY_PARAMS.ASSESSMENT_YEAR] || null,
        type: urlDataObj?.[COMMON_QUERY_PARAMS.E_PROCEEDING_TYPE] || null,
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] ===
          DurationType.Custom && {
          period: {
            start: urlDataObj?.[COMMON_QUERY_PARAMS.START]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.START],
                  defaultDateFormat,
                ).startOf('day')
              : '',
            end: urlDataObj?.[COMMON_QUERY_PARAMS.END]
              ? dayjs(
                  urlDataObj?.[COMMON_QUERY_PARAMS.END],
                  defaultDateFormat,
                ).endOf('day')
              : '',
          },
        }),
        status: urlDataObj?.[COMMON_QUERY_PARAMS.STATUS] || null,
        skip: urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]
          ? (Number(urlDataObj?.[COMMON_QUERY_PARAMS.PAGE]) - 1) * LIMIT
          : 0,
        search,
        limit: Number(urlDataObj?.[COMMON_QUERY_PARAMS.LIMIT]) || LIMIT,
        duration: urlDataObj?.[COMMON_QUERY_PARAMS.DURATION] || null,
        ...(urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON] && {
          isIssuedOn: !urlDataObj?.[COMMON_QUERY_PARAMS.IS_ISSUED_ON],
        }),
      },
      sort: prepareSorterFromURL as EProceedingsSort[],
    };
  }, [location.search, isTableSorterChange, search]);

  const {
    data: { eProceedings = {} } = {},
    loading,
    refetch,
  } = useQuery(E_PROCEEDINGS_LIST, {
    variables: prepareQueryVariableFromUrl,
    fetchPolicy: 'network-only',
    onError() {},
  });

  const [updateStatus, { loading: updateStatusLoading }] = useMutation(
    UPDATE_E_PROCEEDING_STATUS,
    {
      onCompleted: () => {
        refetch(prepareQueryVariableFromUrl);
        statusForm.resetFields();
        setIsModalOpen(false);
        setSelectedRowKeys(() => ({}));
        resetPagination();
        navigate(`${location?.pathname}?${searchParams.toString()}`, {
          replace: true,
        });
      },
    },
  );

  const handlePagination = (page: number) => {
    searchParams.set(COMMON_QUERY_PARAMS.PAGE, page.toString());
    searchParams.set(COMMON_QUERY_PARAMS.LIMIT, LIMIT.toString());
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleClear = () => {
    if (id) {
      const prepareQuery = {
        [COMMON_QUERY_PARAMS.CLIENT_LABEL]: clientLabel ?? '',
        [COMMON_QUERY_PARAMS.CLIENT]: client ?? '',
        [COMMON_QUERY_PARAMS.NAME]: name ?? '',
      };
      navigate(
        `${location?.pathname}?${new URLSearchParams(prepareQuery).toString()}`,
      );
    } else {
      filterForm.resetFields();
      navigate(`${location?.pathname}`, { replace: true });
    }
  };

  const rowSelection: TableProps<EProceeding>['rowSelection'] = {
    selectedRowKeys: selectedRowKeys?.[currentPage],
    onChange: (newSelectedRowKeys: Key[]) => {
      setSelectedRowKeys((prev) => ({
        ...prev,
        [currentPage]: newSelectedRowKeys,
      }));
    },
    ...(selectedRowKeys[currentPage]?.length && {
      columnTitle: () => (
        <span
          className="d-flex pointer"
          onClick={() => {
            setSelectedRowKeys((prev) => ({
              ...prev,
              [currentPage]: [],
            }));
          }}
        >
          <Minus />
        </span>
      ),
    }),
  };

  const cancelModal = () => {
    setIsModalOpen(false);
    statusForm.resetFields();
  };

  const handleResponse = (record: EProceeding) => {
    if (record) {
      navigate(`${record?._id}${ROUTES.RESPONSE}?response=true`);
    }
  };

  const handleAttachments = (item: EProceeding) => {
    if (item.closureOrderAttachment!.length > 0) {
      setOpenModal(true);
      setAttachment(item?.closureOrderAttachment as Attachment[]);
    } else {
      setOpenModal(false);
      setAttachment([]);
    }
  };

  const getSorter = (fieldName: string) => {
    let order = null;
    if (prepareQueryVariableFromUrl?.sort?.length > 0) {
      prepareQueryVariableFromUrl?.sort?.find((item) => {
        if (item?.sortOn === fieldName) {
          order = item?.sortBy === SortOrder.Asc ? 'ascend' : 'descend';
        }
      });
    }
    return order;
  };

  const columns: ColumnType<EProceeding>[] = [
    {
      title: LABEL_TEXT.PAN,
      dataIndex: 'panNumber',
      key: 'panNumber',
      fixed: 'left',
      render: (_, recode) => (recode ? recode?.assessee?.username : '-'),
    },
    {
      title: LABEL_TEXT.NAME_OF_ASSESSEE,
      dataIndex: 'assessee-name',
      key: 'assessee-name',
      fixed: 'left',
      render: (_, recode) => {
        return recode ? recode?.assessee?.name : '-';
      },
    },
    {
      title: LABEL_TEXT.ASSESSMENT_YEAR,
      dataIndex: 'assessmentYear',
      key: 'assessmentYear',
      fixed: 'left',
      sorter: { multiple: 1 },
      sortOrder: getSorter('assessmentYear'),
      render: (assessmentYear) => assessmentYear || '-',
    },
    {
      title: LABEL_TEXT.FINANCIAL_YEAR,
      dataIndex: 'financialYear',
      key: 'financialYear',
      sorter: { multiple: 2 },
      sortOrder: getSorter('financialYear'),
      render: (financialYear) => financialYear || '-',
    },
    {
      title: LABEL_TEXT.REFERENCE_ID,
      dataIndex: 'noticeReferenceId',
      key: 'noticeReferenceId',
      render: (noticeReferenceId) => noticeReferenceId || '-',
    },
    {
      title: LABEL_TEXT.PROCEEDINGS_NAME,
      dataIndex: 'name',
      key: 'name',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.ISSUED_DATE,
      dataIndex: 'issuedOn',
      key: 'issuedOn',
      sorter: { multiple: 3 },
      sortOrder: getSorter('issuedOn'),
      render: (issuedOn) =>
        issuedOn ? dayjs(issuedOn).format(defaultDateFormat) : '-',
    },
    {
      title: LABEL_TEXT.RESPONSE_DUE_DATE,
      key: 'responseDueDate',
      dataIndex: 'responseDueDate',
      render: (_, item) =>
        item?.responseDueDate ? (
          item?.isDueDateCalculated === true ? (
            <Tooltip title="* shows +7 days from the issue date">
              {`${dayjs(item.responseDueDate).format(defaultDateFormat)} `}
              <span className="fs-16 color-main">*</span>
            </Tooltip>
          ) : (
            dayjs(item.responseDueDate).format(defaultDateFormat)
          )
        ) : (
          '-'
        ),
      sorter: { multiple: 4 },
      sortOrder: getSorter('responseDueDate'),
      defaultSortOrder: 'descend',
    },
    {
      title: LABEL_TEXT.PROCEEDING_CLOSURE_DATE,
      dataIndex: 'proceedingCloseDate',
      key: 'proceedingCloseDate',
      render: (proceedingClosureDate) =>
        proceedingClosureDate
          ? dayjs(proceedingClosureDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.PROCEEDING_CLOSURE_ORDER,
      key: 'proceedingCloseOrder',
      render: (item) =>
        item?.proceedingCloseOrder ? (
          <a
            onClick={() => handleAttachments(item)}
            className={`${!(item?.closureOrderAttachment?.length > 0) ? 'pointer-not-allowed' : 'pointer'}`}
          >
            {item?.proceedingCloseOrder}
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: LABEL_TEXT.PROCEEDING_LIMITATION_DATE,
      dataIndex: 'proceedingLimitationDate',
      key: 'proceedingLimitationDate',
      render: (proceedingLimitationDate) =>
        proceedingLimitationDate
          ? dayjs(proceedingLimitationDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.EMAIL,
      dataIndex: ['letterPdf', 'to'],
      key: 'email',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.NOTICE_US,
      dataIndex: 'noticeUs',
      key: 'noticeUs',
      render: (noticeUs) => (!isEmpty(noticeUs) ? noticeUs : '-'),
    },
    {
      title: LABEL_TEXT.DOCUMENT_REFERENCE_ID,
      dataIndex: 'documentRefId',
      key: 'documentRefId',
      render: (text) => text || '-',
    },
    {
      title: LABEL_TEXT.DESCRIPTION,
      dataIndex: 'description',
      key: 'description',
      render: (text) => text || '-',
    },

    {
      title: LABEL_TEXT.PROCEEDING_OPEN_DATE,
      dataIndex: 'proceedingOpenDate',
      key: 'proceedingOpenDate',
      render: (proceedingOpenDate) =>
        proceedingOpenDate
          ? dayjs(proceedingOpenDate).format(defaultDateFormat)
          : '-',
    },
    {
      title: LABEL_TEXT.TYPE_OF_PROCEEDING,
      dataIndex: 'type',
      key: 'type',
      render: (remarks) => {
        let text = '';
        switch (remarks) {
          case EProceedingType.Fya:
            text = 'For your action';
            break;
          case EProceedingType.Fyi:
            text = 'For your information';
            break;
          default:
            text = '';
        }
        return text;
      },
    },
    {
      title: LABEL_TEXT.REMARKS,
      dataIndex: 'remarkByAdmin',
      key: 'remarkByAdmin',
      render: (remarks) => (remarks ? remarks : '-'),
    },
    {
      title: LABEL_TEXT.STATUS,
      dataIndex: 'status',
      fixed: 'right',
      key: 'status',
      render: (status) => <StatusTag status={status} />,
      sorter: { multiple: 5 },
      sortOrder: getSorter('status'),
    },
    {
      title: LABEL_TEXT.ACTION,
      fixed: 'right',
      render: (record: EProceeding) => {
        const pdf = record?.letterPdf?.attachments?.[0]?.url;
        const response = record?.responses?.[0];
        return (
          <Space>
            <Link
              className={`${!pdf ? 'pointer-not-allowed' : 'pointer'}`}
              to={pdf ?? ''}
              {...(pdf && {
                download: { pdf },
                target: '_blank',
              })}
            >
              <FilePDF {...(!pdf && { color: '#C6C6C6' })} />
            </Link>
            <span
              key="edit"
              onClick={() => {
                setIsModalOpen(true);
                statusForm.setFieldsValue({
                  statusForm: {
                    status: record.status,
                    remark: record.remarkByAdmin,
                    id: [record._id],
                  },
                });
              }}
              className="pointer"
            >
              <Pencil />
            </span>
            <span
              onClick={() => response && handleResponse(record)}
              className={`${!response ? 'pointer-not-allowed' : 'pointer'}`}
            >
              <EyeIcon key="view" {...(!response && { color: '#C6C6C6' })} />
            </span>
            <Tooltip placement="top" title={AI_TOOLTIP_MESSAGE}>
              <Button
                type="default"
                icon={<Icon />}
                className="d-flex align-center ask-ai-btn pointer p-0"
                onClick={() => window.open(askAI)}
              >
                {LABEL_TEXT.ASK_AI}
              </Button>
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const handleTableChange: TableProps['onChange'] = (_, _filters, sorter) => {
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    Object.values(fieldToParams).forEach((item) => {
      if (searchParams.has(item as string)) {
        searchParams.delete(item);
      }
    });
    if (sorterAry && Array.isArray(sorterAry)) {
      if (!isTableSorterChange) {
        setIsTableSorterChange(true);
        sorterAry.splice(0, 1);
      }
      sorterAry.forEach((item) => {
        const field = item.field as keyof typeof fieldToParams;
        if (item?.order) {
          searchParams.set(
            fieldToParams[field],
            item?.order === 'descend' ? SortOrder.Desc : SortOrder.Asc,
          );
        }
      });
      navigate(`${location?.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  };

  const exportCsv = async () => {
    const response = await axiosInstance.post('/v1/eproceeding/export-csv', {
      filter: {
        ...omit(prepareQueryVariableFromUrl.filter, ['limit', 'skip']),
        ids: totalSelectedRows,
      },
      sort: prepareQueryVariableFromUrl?.sort,
    });
    downloadCsv(response);
    setSelectedRowKeys(() => ({}));
  };

  const updateStateForm = (value: FormValues) => {
    updateStatus({
      variables: {
        where: {
          ids: value?.statusForm?.id,
        },
        data: {
          remarkByAdmin: value?.statusForm?.remark,
          status: value?.statusForm?.status,
        },
      },
    });
  };

  const handleFieldsNavigation = (
    fieldType: string,
    value: string | null,
    additionalParams?: Record<string, string | null>,
  ) => {
    resetPagination();
    if (value === '' || value) {
      searchParams.set(fieldType, value);
    } else {
      if (searchParams.has(fieldType)) {
        searchParams.delete(fieldType);
      }
    }
    if (additionalParams) {
      Object.entries(additionalParams).forEach(([key, val]) => {
        if (val) {
          searchParams.set(key, val);
        } else if (searchParams.has(key)) {
          searchParams.delete(key);
        }
      });
    }
    navigate(`${location?.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const handleDurationNavigation = (e: string) => {
    if (
      searchParams.has(COMMON_QUERY_PARAMS.START) ||
      searchParams.has(COMMON_QUERY_PARAMS.END)
    ) {
      searchParams.delete(COMMON_QUERY_PARAMS.START);
      searchParams.delete(COMMON_QUERY_PARAMS.END);
    }
    resetPagination();
    handleFieldsNavigation(COMMON_QUERY_PARAMS.DURATION, e);
  };

  return (
    <div className="container">
      <div className="mt-16 mb-16">
        <MyBreadcrumb
          username={
            eProceedings?.data?.[0]?.assessee?.username ?? (clientLabel || '')
          }
          id={eProceedings?.data?.[0]?.assessee?._id ?? (client || '')}
          assesseeId={eProceedings?.data?.[0]?.assessee?._id ?? (client || '')}
        />
      </div>
      <div className="d-flex justify-between align-center">
        <Text className="heading">{LABEL_TEXT.INCOME_TAX_NOTICE}</Text>
        <CommonSearch
          handleChangeSearch={(val) => {
            setSearch(val);
          }}
          iconPlacement="right"
          allowClear
        />
      </div>
      <div className="d-flex mt-16 gap-16">
        <Button
          type="primary"
          className="d-flex align-center"
          icon={<Export />}
          onClick={() => exportCsv()}
        >
          {LABEL_TEXT.EXPORT_TO_CSV}
        </Button>
        <Button
          type="default"
          className="d-flex align-center"
          onClick={async () => {
            setIsModalOpen(true);
            statusForm.setFieldsValue({
              statusForm: {
                id: totalSelectedRows,
              },
            });
          }}
          disabled={!(totalSelectedRows.length > 0)}
        >
          {LABEL_TEXT.BULK_UPDATE_STATUS}
        </Button>
      </div>
      <Card className="mt-16 table-card">
        <div className="d-flex flex-vertical gap-16">
          <Form
            form={filterForm}
            layout="vertical"
            className={`filter-form ${filters?.duration === DurationType.Custom ? 'custom-active' : ''}`}
          >
            <div className="filters d-flex align-center gap-16">
              <Form.Item
                name={['filters', 'assesseeId']}
                label={LABEL_TEXT.SELECT_CLIENT}
                className="select pointer max-width"
              >
                <InfiniteSelect
                  query={ASSESSEES_LIST}
                  variableSelector={({ skip, limit, search }) => ({
                    filter: {
                      skip,
                      limit,
                      search,
                      withName: false,
                    },
                    sort: {
                      sortBy: SortOrder.Desc,
                    },
                  })}
                  dataSelector={({ assessees }) => {
                    return (
                      assessees?.data?.map((item) => ({
                        label: (
                          <Tooltip
                            title={[item?.username, item?.name].join(' | ')}
                            placement="top"
                          >
                            {[item?.username, item?.name].join(' | ') ?? ''}
                          </Tooltip>
                        ),
                        value: item?._id ?? '',
                      })) ?? []
                    );
                  }}
                  countSelector={({ assessees }) => assessees?.count ?? 0}
                  allowSearch
                  placeholder="Pan, Username"
                  allowClear={!id}
                  disabled={!!id}
                  fetchPolicy="network-only"
                  /* any used to onchange value type incompatibility */
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  onChange={(e: any) => {
                    const client = e?.value as string;
                    const name = e?.label?.props?.title?.split(' | ')[1];
                    const clintLab = e?.label?.props?.title?.split(' | ')[0];
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.ASSESSEE_ID,
                      e && (e.value as string),
                      {
                        [COMMON_QUERY_PARAMS.CLIENT]: client,
                        [COMMON_QUERY_PARAMS.NAME]: name,
                        [COMMON_QUERY_PARAMS.CLIENT_LABEL]: clintLab,
                      },
                    );
                    setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'assessmentYear']}
                label={LABEL_TEXT.FULL_ASSESSMENT_YEAR}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={generateYearOptions(ITR_FILL_START_YEAR)}
                  allowClear
                  showSearch
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.ASSESSMENT_YEAR,
                      e ?? null,
                    );
                    setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'type']}
                label={LABEL_TEXT.TYPE_OF_PROCEEDING}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={E_PROCEEDINGS_TYPE}
                  allowClear
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.E_PROCEEDING_TYPE,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'status']}
                label={LABEL_TEXT.STATUS}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={E_PROCEEDINGS_STATUS}
                  allowClear
                  onChange={(e: string) => {
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.STATUS,
                      e ?? null,
                    ),
                      setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'name']}
                label={LABEL_TEXT.PROCEEDINGS_NAME}
                className="select pointer max-width"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={PROCEEDING_NAME_LIST}
                  allowClear
                  showSearch
                  onChange={(e: string) => {
                    const selectedItem = PROCEEDING_NAME_LIST.find(
                      (item) => item.value === e,
                    );
                    const value =
                      selectedItem?.label === PROCEEDING_NAME.Others ? '' : e;
                    handleFieldsNavigation(
                      COMMON_QUERY_PARAMS.E_PROCEEDING_NAME,
                      value ?? null,
                    );
                    setSelectedRowKeys([]);
                  }}
                />
              </Form.Item>
              <Form.Item
                name={['filters', 'duration']}
                label={LABEL_TEXT.DURATION}
                className="select"
              >
                <Select
                  suffixIcon={<SelectDropdownIcon />}
                  placeholder="All"
                  options={DURATION_TYPE}
                  onChange={(e: string) => {
                    handleDurationNavigation(e), setSelectedRowKeys([]);
                  }}
                  allowClear
                />
              </Form.Item>
              {filters?.duration === DurationType.Custom && (
                <Form.Item
                  name={['filters', 'period']}
                  label={LABEL_TEXT.PERIOD}
                  className="select"
                >
                  <RangePicker
                    allowClear
                    onChange={(date) => {
                      handleDateRangeNavigation(date), setSelectedRowKeys([]);
                    }}
                    format={defaultDateFormat}
                  />
                </Form.Item>
              )}
              <Form.Item className="d-flex align-end align-self-end">
                <Button
                  type="link"
                  onClick={handleClear}
                  disabled={
                    !(
                      (id ? !filters?.assesseeId : filters?.assesseeId) ||
                      filters.assessmentYear ||
                      filters.duration ||
                      filters.status ||
                      filters.type ||
                      filters.name
                    )
                  }
                >
                  {LABEL_TEXT.CLEAR_ALL}
                </Button>
              </Form.Item>
            </div>
          </Form>
          <TableComponent<EProceeding>
            columns={columns}
            dataSource={eProceedings?.data as EProceeding[]}
            pagination={false}
            rowSelection={rowSelection}
            rowKey="_id"
            scroll={{ x: 'max-content' }}
            locale={EMPTY_STATE}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
        {eProceedings?.count ? (
          <CommonPagination
            count={eProceedings?.count}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        ) : null}
      </Card>
      <CommonModal
        open={isModalOpen}
        title="Update Status"
        footer={false}
        closable={true}
        onCancel={cancelModal}
        maskClosable={false}
      >
        <div className="create-forms-form">
          <Form
            onFinish={updateStateForm}
            form={statusForm}
            layout="vertical"
            preserve={false}
          >
            <Form.Item
              label={LABEL_TEXT.STATUS}
              name={['statusForm', 'status']}
              rules={[
                { ...required, message: MESSAGE?.required, whitespace: true },
              ]}
            >
              <Select
                suffixIcon={<SelectDropdownIcon />}
                options={E_PROCEEDINGS_STATUS}
                placeholder="Change Status"
              />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'remark']}
              rules={[{ whitespace: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={LABEL_TEXT.REMARK}
              name={['statusForm', 'id']}
              hidden
            >
              <Select suffixIcon={<SelectDropdownIcon />} mode="multiple" />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              loading={updateStatusLoading}
              disabled={isEmpty(updateBulkStatus?.status)}
            >
              {LABEL_TEXT.UPDATE}
            </Button>
          </Form>
        </div>
      </CommonModal>
      <CommonModal
        className="h-512"
        title="Attachments"
        open={openModal}
        footer={false}
        closable={true}
        onCancel={() => setOpenModal(false)}
        wrapClassName="attachment-modal"
      >
        <div className="d-flex gap-16 flex-vertical">
          {attachments?.map((file: Attachment, index: number) => (
            <div className="d-flex align-center" key={index}>
              <span className="mr-8 d-flex">
                <FilePDF />
              </span>
              <Link
                className="color-blue max-width-430"
                to={file?.url || ''}
                download
                target="_blank"
              >
                {file?.name}
              </Link>
            </div>
          ))}
        </div>
      </CommonModal>
    </div>
  );
};

export default TaxLitigation;

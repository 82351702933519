import { Result } from 'antd';

const MaintenancePage = () => (
  <div className="empty-data d-flex justify-center align-center height">
    <Result
      status="500"
      title={<h1>Sorry! We are under maintenance currently.</h1>}
      subTitle={
        <h2>
          Our website is undergoing scheduled maintenance for the next{' '}
          {process.env.REACT_APP_MAINTENANCE_DURATION}. We apologize for the
          inconvenience and will extend your current plan by{' '}
          {process.env.REACT_APP_MAINTENANCE_DURATION} to make up for the
          downtime. Thank you for your patience!
        </h2>
      }
    />
  </div>
);
export default MaintenancePage;
